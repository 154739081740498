



























import { Vue, Component, Prop } from "vue-property-decorator";
import SectionContent from "@/components/common/SectionContent.vue";
import { SwiperOptions } from "swiper";
import Viewer from "viewerjs";

export interface SliderCarouselItem {
  src: string;
  url?: string;
  alt?: string;
}

@Component({
  components: {
    SectionContent
  }
})
export default class SliderCarousel extends Vue {
  @Prop(Array) readonly items!: SliderCarouselItem[];
  obSwiperOptions: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    height: 300,
    autoHeight: false,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        loop: true
      }
    }
  };

  obViewerOptions: Viewer.Options = {
    inline: false,
    button: true,
    navbar: false,
    title: false,
    toolbar: false,
    tooltip: false,
    movable: false,
    zoomable: false,
    rotatable: false,
    scalable: false,
    transition: true,
    fullscreen: false,
    keyboard: false,
    url: "data-src"
  };
}
